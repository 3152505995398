const state = {
    qy_id: '',
    id: ''
}

const mutations = {
    SAVEQY_ID(state,qy_id){
        state.qy_id = qy_id
    },
    SAVEID(state,id){
        state.id = id
    }
}

const actions = {
    saveQy_id({commit},qy_id){
        commit('SAVEQY_ID',qy_id)
    },
    saveid({commit},id){
        commit('SAVEID',id)
    }
}

const getters = {}


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}