
const state = {
    list: [],
    arr: [],
    num: '',
    sums: {},
    m_rout: {},
    Do_searchs: {},
    subs_list: {}
}
const getters = {
    list: (state) => state.list,
    arr: (state) => state.arr,
    num: (state) => state.num,
    sums: (state) => state.sums
}
const mutations = {
    SET_MENU: (state, list) => {
        state.list = list
    },
    SET_ARR: (state, arr) => {
        state.arr = arr
    },
    SET_NUM: (state, num) => {
        state.num = num
    },
    SET_SUMS: (state, sums) => {
        state.sums = sums
    },
    SET_SEARCH: (state, Do_searchs) => {
        state.Do_searchs = Do_searchs
    },
    SET_ROUT: (state, m_rout) => {
        if (m_rout.name !== '首页') {
            state.m_rout = m_rout
        }
    },
    SET_SUBS: (state, subs_list) => {
        state.subs_list = subs_list
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    getters
}
