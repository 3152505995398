export const client = {
    mounted(el){
        if(equipmentPcOrMobile() != '来自于PC端浏览器'){
            el.parentNode && el.parentNode.removeChild(el);
        }
    }
}

const equipmentPcOrMobile = () => {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag ?  is_weixin() ? "来自于APP端企业微信" : "" : is_weixin() ? "来自于PC端的企业微信" : "来自于PC端浏览器"
}

const is_weixin = () => {
    return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}