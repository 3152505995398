import {client} from './useClient'
import {permission} from './permission'

const directives = {
    client,
    permission
}

export default{
    install(Vue){
        Object.keys(directives).forEach((directive)=>{
            Vue.directive(directive,directives[directive])
        })
    }
}