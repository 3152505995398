import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'
import '@/style/common.scss'
import 'default-passive-events'
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
// 企业微信包
import * as ww from '@wecom/jssdk'
import 'dayjs/locale/zh-cn'

import Directives from '@/directives'
const baseSize = 50
// 设置 rem 函数
function setRem () {
  // 当前页面宽度相对于 750 宽的缩放比例，可根据自己需要修改。
  const scale = document.documentElement.clientWidth / 750
  // 设置页面根节点字体大小
  document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}


let openMock = false

// import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// use(ElementPlus).

// const app = createApp()
// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
//   app.component(key, component)
// }
createApp(App).provide('ww',ww).use(Directives).use(store).use(router).mount('#app')