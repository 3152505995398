import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { ElConfigProvider } from 'element-plus';
// import {GetAuthory} from '@/api/common/authory'

import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
export default {
  components: {
    ElConfigProvider
  },
  setup() {
    const store = useStore();
    const debounce = (fn, delay) => {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn.apply(context, args);
        }, delay);
      };
    };
    const _ResizeObserver = window.ResizeObserver;
    window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
      constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
      }
    };
    onMounted(async () => {
      // const res = await GetAuthory()
      // if(res.code === 200){
      //     const account_type_list = []
      //     const contract_relation = []
      //     const contract_type = []
      //     const department_leader_list = []
      //     const department_list = []
      //     const diploma_type = []
      //     const education_type = []
      //     const gender_list = []
      //     const marital_status_list = []
      //     const politics_status_list = []
      //     const quit_msg = []
      //     const quit_type = []
      //     const rank_list = []
      //     const seniority_interval_list = []
      //     const user_status = []
      //     const staff_type_list = []
      //     const trial_period_list = []
      //     const position_list = []
      //     for(let i in res.data.user_status_list){
      //         user_status.push({
      //             id: Number(i),
      //             text: res.data.user_status_list[i]
      //         })
      //     }
      //     for (let i in res.data.rank_list) {
      //         rank_list.push({
      //             id: i,
      //             text: res.data.rank_list[i]
      //         })
      //     }
      //     for (let i in res.data.position_list) {
      //         position_list.push({
      //             id: i,
      //             text: res.data.position_list[i]
      //         })
      //     }
      //     for (let i in res.data.seniority_interval_list) {
      //         seniority_interval_list.push({
      //             id: i,
      //             text: res.data.seniority_interval_list[i]
      //         })
      //     }
      //     for (let i in res.data.staff_type_list) {
      //         staff_type_list.push({
      //             id: Number(i),
      //             text: res.data.staff_type_list[i]
      //         })
      //     }
      //     for (let i in res.data.trial_period_list) {
      //         trial_period_list.push({
      //             id: i,
      //             text: res.data.trial_period_list[i]
      //         })
      //     }
      //     for (let i in res.data.quit_type) {
      //         quit_type.push({
      //             id: Number(i),
      //             text: res.data.quit_type[i]
      //         })
      //     }
      //     for (let i in res.data.account_type_list) {
      //         account_type_list.push({
      //             id: i,
      //             text: res.data.account_type_list[i]
      //         })
      //     }
      //     for (let i in res.data.contract_relation) {
      //         contract_relation.push({
      //             id: i,
      //             text: res.data.contract_relation[i]
      //         })
      //     }
      //     for (let i in res.data.education_type) {
      //         education_type.push({
      //             id: i,
      //             text: res.data.education_type[i]
      //         })
      //     }
      //     for (let i in res.data.gender_list) {
      //         gender_list.push({
      //             id: Number(i),
      //             text: res.data.gender_list[i]
      //         })
      //     }
      //     for (let i in res.data.marital_status_list) {
      //         marital_status_list.push({
      //             id: i,
      //             text: res.data.marital_status_list[i]
      //         })
      //     }
      //     for (let i in res.data.politics_status_list) {
      //         politics_status_list.push({
      //             id: i,
      //             text: res.data.politics_status_list[i]
      //         })
      //     }
      //     for (let i in res.data.quit_msg) {
      //         quit_msg.push({
      //             id: Number(i),
      //             text: res.data.quit_msg[i]
      //         })
      //     }

      //     for (let i in res.data.diploma_type) {
      //         diploma_type.push({
      //             id: i,
      //             text: res.data.diploma_type[i]
      //         })
      //     }
      //     for (let i in res.data.department_list) {
      //         department_list.push({
      //             id: Number(i),
      //             text: res.data.department_list[i]
      //         })
      //     }
      //     for (let i in res.data.department_leader_list) {
      //         department_leader_list.push({
      //             id: Number(i),
      //             text: res.data.department_leader_list[i]
      //         })
      //     }
      //     for (let i in res.data.contract_type) {
      //         contract_type.push({
      //             id: Number(i),
      //             text: res.data.contract_type[i]
      //         })
      //     }
      //     const result = {
      //         account_type_list,
      //         contract_relation,
      //         contract_type,
      //         department_leader_list,
      //         department_list,
      //         diploma_type,
      //         education_type,
      //         gender_list,
      //         marital_status_list,
      //         politics_status_list,
      //         quit_msg,
      //         quit_type,
      //         rank_list,
      //         seniority_interval_list,
      //         staff_type_list,
      //         trial_period_list,
      //         position_list,
      //         user_status,
      //         nation_list: res.data.nation_list,
      //         province_list: res.data.province_list,
      //         zh_bank_list: res.data.zh_bank_list,
      //         code: res.data.code || 'A'
      //     }
      //     store.dispatch('init/wechat_init',result)
      // }

      // window.onbeforeunload = function(){
      //     if(performance.navigation.type === null ){
      //         localStorage.removeItem('threeLvel')
      //         localStorage.removeItem('menuIndex')
      //     }
      // }
      // window.onload = function(){
      //     if(window.performance.navigation.type == 0 ){
      //         store.dispatch('threeLevel','/pc_workbench_index')
      //         store.dispatch('changeSelectMenu','home')
      //     }
      // }
    });
    return {
      zhCn
    };
    // const router = useRouter()
    // const route = useRoute()
    // const ww = inject('ww')
    // const equipmentPcOrMobile = () => {
    //     let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    //     return flag
    // }
    // onMounted(async() => {
    //     // if (equipmentPcOrMobile()) {
    //     //     router.replace('/mb_index')
    //     // } else {
    //     //     router.replace('/pc_index')
    //     // }
    // })
  }
};