import { createStore } from 'vuex'
import hear from './modules/hear'
import popover from './modules/popover'
import init from './modules/init'

export default createStore({
  state: {
    selectIndex: 0,
    menuIndex: localStorage.getItem('menuIndex') || null,
    threeLvel: localStorage.getItem('threeLvel') || null,
    user_info: JSON.parse(localStorage.getItem('user_info')) || {}
  },
  getters: {
  },
  mutations: {
    EDITSELECTINDEX: (state,selectIndex) => {
      state.selectIndex = selectIndex
    },
    CHANGESELECTINDEX: (state,selectMenuIndex) => {
      state.menuIndex = selectMenuIndex
      localStorage.setItem('menuIndex',selectMenuIndex)
    },
    THREELEVEL: (state,threeLvel) => {
      state.threeLvel = threeLvel
      localStorage.setItem('threeLvel',threeLvel)
    },
    USERINFO : (state,user_info) => {
      state.user_info = user_info
      localStorage.setItem('user_info',JSON.stringify(user_info))
    }
  },
  actions: {
    editSelectIndex({commit},selectIndex){
      commit('EDITSELECTINDEX',selectIndex)
    },
    changeSelectMenu({commit},selectMenuIndex){
      commit('CHANGESELECTINDEX',selectMenuIndex)
    },
    threeLevel({ commit },threeLvel){
      commit('THREELEVEL', threeLvel)
    },
    saveUserInfo({commit},user_info){
      commit('USERINFO',user_info)
    },
  },
  modules: {
    hear,
    popover,
    init
  }
})
