const state = {
    wechat_init: {}
}

const mutations = {
    SAVE_WECHAT_INIT(state,result){
        console.log(result)
        state.wechat_init = result
    }
}

const actions = {
    wechat_init({commit},result){
        commit('SAVE_WECHAT_INIT',result)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}