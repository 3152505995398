import { createRouter, createWebHashHistory } from 'vue-router'
// import { useStore } from 'vuex';
import store from '@/store'

// const menus = [
//   {
//       route: '/pc_home',
//       name: '首页'
//   },
//   {
//       route: '/pc_roster',
//       name: '花名册'
//   },
//   {
//       route: '/pc_roster_import',
//       name: '导入记录'
//   },
//   {
//       route: '/pc_entry',
//       name: '入职管理'
//   },
//   {
//       route: '/pc_applyFull',
//       name: '转正管理'
//   },
//   {
//       route: '/pc_dismission',
//       name: '离职管理'
//   },
//   {
//       route: '/pc_transaction',
//       name: '异动记录'
//   },
// ]


const routes = [
  {
    path: '/login',
    name: 'AllLogin',
    component: () => import("@/components/common/login.vue")
  },
  {
    path: '/browser/login',
    name: 'BroswerLogin',
    component: () => import("@/views/pc/login/login.vue")
  },
  {
    // /pc_index
    path: '/pc_index',
    name: 'PcIndex',
    component: () => import("@/views/pc/home/index.vue"),
    redirect: '/pc_workbench_index',
    children:[
      {
        path: '/pc_workbench',
        name: 'pcWorkbench',
        component: () => import("@/views/pc/workbench/workbench.vue"),
        children: [
          {
            path: '/pc_workbench_index',
            name: 'pcWorkBenchIndex',
            component: () => import("@/views/pc/workbench/workBenchIndex.vue")
          },
        ]
      },
      {
        path: '/pc_home',
        name: 'pcHome',
        component: () => import("@/views/pc/home/home.vue"),
        children: [
          {
            path: '/pc_personnelHome',
            name: 'pcPersonnelHome',
            component: () => import("@/views/pc/home/personnelHome.vue")
          },
          {
            path: '/pc_roster',
            name: 'pcRoster',
            component: () => import("@/views/pc/roster/index.vue")
          },
          {
            path: '/pc_entry',
            name: 'pcEntry',
            component: () => import("@/views/pc/onboarding/entry.vue")
          },
          {
            path: '/pc_applyFull',
            name: 'pcApplyFull',
            component: () => import("@/views/pc/onboarding/applyFull.vue")
          },
          {
            path: '/pc_dismission',
            name: 'pcDismission',
            component: () => import("@/views/pc/onboarding/dismission.vue")
          },
          {
            path: '/pc_transaction',
            name: 'pcTransaction',
            component: () => import("@/views/pc/transaction/index.vue")
          },
          {
            path: '/pc_upload',
            name: 'PcUpload',
            component: () => import('@/views/pc/upload/upload.vue')
          },
          {
            path: '/pc_addEmployInfo',
            name: 'PcAddEmployInfo',
            component: () => import('@/views/pc/roster/addEmployInfo.vue')
          },
          
          {
            path: '/pc_detail/',
            name: 'PcDetailNone',
            redirect: '/pc_detail/0'
          },
          {
            path: '/pc_detail/:id',
            name: 'PcDetail',
            component: () => import('@/views/pc/roster/detail.vue')
          },
          {
            path: '/pc_roster_import',
            name: 'PcRosterImport',
            component: () => import('@/views/pc/roster/importFile.vue')
          },
          {
            path: '/pc_roster_import_detail/:id',
            name: 'PcRosterImportDetail',
            component: () => import('@/views/pc/roster/importFileDetail.vue')
          },
          {
            path: '/pc_quit_user_list',
            name: 'PcQuitUserList',
            component: () => import('@/views/pc/transaction/quitUserList.vue')
          },
          {
            path: '/pc_contract_ledger',
            name: 'PcContractLedger',
            component: () => import('@/views/pc/contract/ledger.vue')
          },
          {
            path: '/attendance/statement',
            name: 'AttendanceStatement',
            component: () => import('@/views/pc/attendance/statement.vue')
          },
          {
            path: '/attendance/statistics',
            name: 'AttendanceStatistics',
            component: () => import('@/views/pc/attendance/statistics.vue')
          },
          {
            path: '/approval/initate',
            name: 'ApprovalInitate',
            component: () => import('@/views/pc/approval/initiate.vue')
          },
          {
            path: '/approval/ccmy',
            name: 'ApprovalCcmy',
            component: () => import('@/views/pc/approval/ccmy.vue')
          },
          {
            path: '/approval/handling',
            name: 'ApprovalHandling',
            component: () => import('@/views/pc/approval/handling.vue')
          },
          {
            path: '/approval/pending',
            name: 'ApprovalPending',
            component: () => import('@/views/pc/approval/pending.vue')
          },
          {
            path: '/approval/submitted',
            name: 'ApprovalSubmitted',
            component: () => import('@/views/pc/approval/submitted.vue')
          },
          {
            path: '/approval/all',
            name: 'ApprovalAll',
            component: () => import('@/views/pc/approval/all.vue')
          },
          {
            path: '/account/relevance',
            name: 'AccountRelevance',
            component: () => import("@/views/pc/enterpriseWechat/memoformat.vue")
          },
        ]
      },
    ]
  },
  {
    path: '/mb_index',
    name: 'MbIndex',
    component: () => import("@/views/mb/home/index.vue"),
    redirect: '/pc_workbench_index',
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const equipmentPcOrMobile = () => {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag
}


const equipmentPcOrMobile1 = () => {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag ?  is_weixin() ? "来自于APP端企业微信" : "" : is_weixin() ? "来自于PC端的企业微信" : "来自于PC端浏览器"
}

const is_weixin = () => {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}


const extractedValue = (originalString) => {
  const firstSlashIndex = originalString.indexOf('/');
  if (firstSlashIndex !== -1) {
    const secondSlashIndex = originalString.indexOf('/', firstSlashIndex + 1);
    if (secondSlashIndex !== -1) {
      return originalString.slice(firstSlashIndex + 1, secondSlashIndex);
    }
  }
  return '未找到值';
}



router.beforeEach((to, from, next) => {
  // if (env.is_local()) {
  //   localStorage.setItem('token', '759bbac9d03a4ccd2912657429959f16');
  //   localStorage.setItem('qy_out_user_id', 'wmf7WCBgAARd5tZpMHzokNEeSUxn-Stg');
  //   localStorage.setItem("loadWxConfig", 1);
  // }
  const token = localStorage.getItem('token');
  if (token) {
    if (to.path == '/login' || to.path == '/browser/login') {
      next('/pc_index')
    } else {
      if(to.path == "/"){
        if (equipmentPcOrMobile()) {
          next('/mb_index')
        } else {
          next('/pc_index')
        }
      }else{
        if(to.name == "pcWorkBenchIndex"){
          store.dispatch('changeSelectMenu','home')
        }else{
          if(extractedValue(to.path) != '未找到值'){
            if(to.name == 'PcDetail' || to.name == "PcRosterImportDetail"){
              store.dispatch('changeSelectMenu','personnel')
            }else{
              store.dispatch('changeSelectMenu',extractedValue(to.path))
            }
          }else{
            store.dispatch('changeSelectMenu','personnel')
          }
        } 
        store.dispatch('threeLevel',to.path)
        next()
      }
    }
  } else {
    if (to.path == '/login' || to.path == '/browser/login') {
      next()
    } else {
      if(equipmentPcOrMobile1() == '来自于PC端浏览器'){
        next('/browser/login')
      }else{
        next('/login');
      }
    }
  }
});
export default router
